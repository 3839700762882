import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import unicornbikeImg from "./../assets/images/unicornbike.jpg";
import auth from "../auth/auth-helper";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import Header from "./Header";
import { Navigate, RouterProvider } from "react-router-dom";
import router from "../Router";
import { display } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1
  },
  content: {
    marginTop: '64px',
    display: 'flex',
    flex: 1,
    flexDirection:'column',
    alignItems: 'middle',
    justifyContent: 'center',
    background: '#eee'
  }
}));

export default function Customer() {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false)
    function toggleDrawer () {
        setOpenDrawer(!openDrawer)
    }
    return (
        <div className={classes.root}>
            Customer
        </div>
    );
}
