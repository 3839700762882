import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { UserContext } from "../user/user-context";
import auth from "./auth-helper";

const AdminRoute = ({ children }) => {
    const { user, setUser } = useContext(UserContext);
    if ( user && user.admin) {
        return children;  
    }
    return <Navigate to="/signin" replace />;
};

export default AdminRoute;