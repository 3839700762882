import axios from "axios";
import headerInterceptor from "./header-interceptor";
import errorInterceptor from "./error-interceptor";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// errorInterceptor(httpClient);
headerInterceptor(httpClient);

export default httpClient;
