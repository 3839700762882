import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";

import Stack from "@mui/material/Stack";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import auth from "../auth/auth-helper";
import { readCredit, readUser, redeemedCredits } from "./api-credits";
import { UserContext } from "../user/user-context";
import EnhancedTable from "../core/EnhancedTable";
import CreditsControl from "./CreditControls";
import AddCredits from "./AddCredits";
import RedeemCredits from "./RedeemCredits";
import { CircularProgress } from "@mui/material";
import CreditsCard from "./BasicCard";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    position: "relative",
    width: "100vw",
    overflowY: "auto",
    paddingBottom: "64px",
    overflow: "scroll",
  },
  content: {
    marginTop: "64px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#eee",
  },
  title: {
    padding: "16px",
    flex: 1,
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  titleCnt: {
    display: "flex",
    justifyContent: "center",
  },
  media: {
    minHeight: 400,
  },
  sumbit: {
    margin: "10px",
    width: "auto",
    alignItems: "middle",
    justifyContent: "center",
  },
  btns: {
    padding: "16px",
    display: "flex",
  },
  credit: {
    padding: 10,
    textAlign: "right",
    backgroundColor: "#ededed",
    borderBottom: "1px solid #d0d0d0",
    "& a": {
      color: "#3f4771",
    },
  },
}));

export default function Credits() {
  const classes = useStyles();
  let { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const [openAdd, setOpenAdd] = useState(false); // close the dialog box
  const [openRedeem, setOpenRedeem] = useState(false); // close the dialog box
  let [ResMessage, setResMessage] = useState("");

  const [selectedUser, setSelectedUser] = useState({}); // close the dialog box
  const [values, setValues] = useState({
    userId: userId,
    credit: "",
    creditName: "",
    remark: "",
    error: "",
  });


  

  const [rows, setRows] = useState([
    {
      credit: "",
      creditName: "",
      remark: "",
      created: "",
    },
  ]);
  const [utilizedRows, setUtilizedRows] = useState([
    {
      utilized: "",
      remark: "",
      created: "",
    },
  ]);

  let headCellsAdmin = [
    {
      id: "_id",
      numeric: false,
      disablePadding: true,
      label: "Loyalty Number",
    },
    {
      id: "creditName",
      numeric: false,
      disablePadding: false,
      label: "Points Type",
    },
    {
      id: "credit",
      numeric: true,
      disablePadding: false,
      label: "Points",
    },
    {
      id: "utilized",
      numeric: true,
      disablePadding: false,
      label: "Utilized",
    },
    {
      id: "created",
      numeric: false,
      disablePadding: false,
      label: "Created",
    },
    {
      id: "expiry",
      numeric: false,
      disablePadding: false,
      label: "Expiry",
    },
    {
      id: "remark",
      numeric: true,
      disablePadding: false,
      label: "Remarks",
    },
  ];

  headCellsAdmin = !user.admin
    ? headCellsAdmin.filter((item) => item.id != "remark")
    : headCellsAdmin;

  const headCellsRedeemed = [
    {
      id: "creditId",
      numeric: false,
      disablePadding: true,
      label: "Loyalty Number",
    },
    {
      id: "remark",
      numeric: false,
      disablePadding: false,
      label: "Remark",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Redeemed",
    },
    {
      id: "created",
      numeric: false,
      disablePadding: false,
      label: "Created",
    },
  ];
  const dateOptions = { month: "short", year: "numeric", day: "numeric" };
  function processRows(rows) {
    return rows.map((row) => {
      let { created, expiry, utilized, credit, amount } = row;
      utilized = parseFloat(utilized);
      credit = parseFloat(credit);
      amount = parseFloat(amount);
      row.utilized = utilized.toLocaleString();
      row.credit = credit ? credit.toLocaleString() : 0;
      row.amount = amount ? amount.toLocaleString() : 0;
      row.credit = `${row.credit}`;
      row.summary = credit ? credit - utilized : amount;
      row.utilized = `${row.utilized}`;
      row.amount = `${row.amount}`;
      row.created = new Date(created).toLocaleDateString("en-US", dateOptions);
      row.expiry = new Date(expiry).toLocaleDateString("en-US", dateOptions);
      return row;
    });
  }
  
  async function loadData() {
    const _userId = values.userId || user.userID;
    try {
      if (!userId) {
        setValues({ ...values, userId: user.userID });
        setSelectedUser(user);
      }
      const creditData = await readCredit(_userId);
      // console.log(JSON.stringify(creditData,null,3));

      const { credit, redeem } = creditData.data;
      setRows(processRows(credit));
      setUtilizedRows(processRows(redeem));
      const userData = await readUser(_userId);
      setSelectedUser(userData.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
   
    
    
    loadData();
  }, [userId]);

  if (loading) {
    return (
      <div className={classes.content}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <Card className={classes.card}>
      <CreditsControl
        selectedUser={selectedUser}
        addCredits={setOpenAdd}
        redeemCredits={setOpenRedeem}
      ></CreditsControl>

      <AddCredits
        selectedUser={selectedUser}
        open={openAdd}
        setOpen={setOpenAdd}
      ></AddCredits>
      <RedeemCredits
        selectedUser={selectedUser}
        open={openRedeem}
        setOpen={setOpenRedeem}
      ></RedeemCredits>

      {user.admin ? (
        <Card>
          <Box sx={{ width: "100%" }}>
            <EnhancedTable
              headCells={headCellsAdmin}
              rows={rows}
              rowId="_id"
              summaryField="summary"
              subTitle="Available Points as of"
              title="Points List"
              setLoading={setLoading}
              loadData={loadData}
            />
            <EnhancedTable
              headCells={headCellsRedeemed}
              rows={utilizedRows}
              rowId="creditId"
              summaryField="summary"
              subTitle="Redeemed Points as of"
              title="Redemption log"
            />
          </Box>
        </Card>
      ) : (
        <CreditsCard
          selectedUser={selectedUser}
          headCells={headCellsAdmin}
          rows={rows}
          rowId="_id"
          summaryField="summary"
          subTitle="Available Points as of"
          title="Points List"
        />
      )}
    </Card>
  );
}
