import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { border, height, textAlign } from "@mui/system";
import { DateRange } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { UserContext } from "../user/user-context";
import { readCredit, readUser, redeemedCredits } from "./api-credits";

const useStyles = makeStyles((theme) => ({


    container: {
        minheight: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    card: {
        width: "300px",
        height: "270px",
        color: "#fff",
        cursor: "pointer",
        perspective: "1000px",
    },

    cardinner: {
        width: "300px",
        height: "170px",
        position: "relative",

        transformStyle: "preserve-3d",
        borderRadius: "10px",
    },

    front: {
        width: "100%",
        height: "170px",
        backgroundImage: "linear-gradient(45deg, #0045c7,#F7819F)",
        position: "absolute",
        top: "0",
        left: "0",
        padding: "20px 30px",
        borderradius: " 15px",
        overflow: "hidden",
        zindex: "1",
        borderRadius: "20px",
        marginTop: "20px"
    },


    row: {
        display: "flex",
        alignitems: "center",
        justifyContent: "space-between",
    },

    mapimg: {
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        opacity: "0.3",
        zindex: "-1",
    },

    cardno: {
        fontsize: "35px",
        margintop: "60px",
        display: "flex",
        justifyContent: "space-between",

    },

    cardholder: {
        fontsize: "12px",
        margintop: "40px",
    },

    name: {
        fontsize: "22px",
        margintop: "20px",
    }









}));

export default function CreditsCard(props) {
    const classes = useStyles();
    const { headCells, rows, rowId, title, summaryField, subTitle } = props;
    const { selectedUser, setOpen, open } = props;

    const summary = summaryField ? rows.reduce((p, c) => p + c[summaryField], 0) : false;




    return (

        // <Card className={classes.card}>
        //     <Card style={{
        //         backgroundImage: `url("https://via.placeholder.com/500")`, background: "linear-gradient(45deg, #0045c7, #ff2c7d)"
        //     }} className={classes.card1}>
        // //       <img className={classes.img} src="https://i.ibb.co/PYss3yv/map.png" alt="card-background" />
        // //       <Typography className={classes.text} variant="h1">{`$${summary}`}</Typography>
        //

        <Box className={classes.container}>
            {console.log(JSON.stringify(selectedUser, null, 3))}
            <Box className={classes.card}>
                <Box className={classes.cardinner}>
                    <Box className={classes.front}>
                        
                        <img src="https://i.ibb.co/PYss3yv/map.png" className={classes.mapimg} />

                        <Box className={classes.cardno}>
                            <p>{selectedUser.userID}</p>

                            <Box className={classes.row}>
                                <img src="https://i.ibb.co/G9pDnYJ/chip.png" width="60px" />
                            </Box>
                        </Box>
                        <Box className={classes.cardholder}>
                            <p>Card Holder : {selectedUser.email}</p>
                            <p>Balance : {`$${summary}`}</p>
                        </Box>
                        <Box className={classes.name}>
                            <p>Created On : {selectedUser.created.slice(0,10)}</p>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>


    );
}



