import api from "../api"

const create = async (data) => {
    return api.post (`/api/update/credits`, data )
};
const updateCredits = async (data) => {
  return api.post (`/api/update/credits`, data )
};

const validateCredits = async (data) => {
  return api.post (`/api/update/credits/validate`, data )
};

const redeemCredits = async (data) => {
  return api.post (`/api/update/credits/redeem`, data )
};

const redeemedCredits = async (data) => {
  return api.get (`/api/update/credits/redeem`, data )
};

const list = async (userId) => {
  return api.get (`/api/credits/user/${userId}`)
};

const readCredit = async ( userId ) => {
    return api.get (`/api/credits/user/${userId}`)
};

const readUser = async ( userId ) => {
  return api.get (`/api/users/${userId}` )
};

const updateExpiryDate = async (data) => {
  return api.put(`/api/update/credits/expirydate`,data);
}

export { create, list, readCredit, readUser, updateCredits, validateCredits, redeemCredits, redeemedCredits, updateExpiryDate  };
