import api from "../api"
const create = async (user) => {
     return api.post ('/api/users/', user )
}

const me = async (user) => {
    return api.get ('/api/users/me' )
}


const list = async () => {
    return api.get ('/api/users/' )
}

const read = async ( userId ) => {
    return api.get (`/api/users/${userId}` )
}

const update = async ( userId, user) => {
    return api.get (`/api/users/${userId}`, user )
}

const remove = async (params, credentials) => {
  try {
    let response = await fetch('/api/users/' + params.userId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + credentials.t
      }
    })
    return await response.json()
  } catch(err) {
    console.log(err)
  }
}

export {
  create,
  me,
  list,
  read,
  update,
  remove
}