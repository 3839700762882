import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Edit from '@material-ui/icons/Edit'
import Person from '@material-ui/icons/Person'
import Divider from '@material-ui/core/Divider'
import DeleteUser from './DeleteUser'
import auth from '../auth/auth-helper'
import {read} from './api-user.js'
import { Link, Navigate} from 'react-router-dom'
import { UserContext } from './user-context'

const useStyles = makeStyles(theme => ({
  root: theme.mixins.gutters({
    maxWidth: 600,
    margin: 'auto',
    padding: theme.spacing(3),
    marginTop: theme.spacing(5)
  }),
  titleCnt: {
    display:'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.protectedTitle,
    flex: 1
  }
}))

export default function Profile({ match }) {
  const classes = useStyles()
  const { user, setUser } = useContext(UserContext);
  
    if (!user) {
      return <Navigate to='/signin'/>
    }
    return (
      <Paper className={classes.root} elevation={4}>
        <div className={classes.titleCnt}>
        <Typography variant="h6" className={classes.title}>
          Profile 
        </Typography>
        <Link to={"/user/edit/"}>
                  <IconButton aria-label="Edit" color="primary">
                    <Edit/>
                  </IconButton>
                </Link>
        </div>
        
        <List dense>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Person/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={user.email}/> 
             {/* <ListItemSecondaryAction>
                <Link to={"/user/edit/"}>
                  <IconButton aria-label="Edit" color="primary">
                    <Edit/>
                  </IconButton>
                </Link>
                <DeleteUser userId={user._id}/>
              </ListItemSecondaryAction>
             */}
          </ListItem>
          <Divider/>
          <ListItem>
            <ListItemText primary={"Joined: " + (
              new Date(user.created)).toDateString()}/>
          </ListItem>
          {/* <ListItem>
                <DeleteUser userId={user._id}/>
          </ListItem> */}
        </List>
      </Paper>
    )
  }