import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import unicornbikeImg from "./../assets/images/unicornbike.jpg";
import auth from "../auth/auth-helper";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import router from "../Router";
import { display } from "@mui/system";
import { CircularProgress } from "@material-ui/core";
import { UserContext } from "../user/user-context";
import { me } from "../user/api-user";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1
  },
  content: {
    marginTop: '64px',
    display: 'flex',
    flex: 1,
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eee'
  }
}));

export default function LandingPage() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    useEffect ( () => {
      if ( user ) {
          if ( user.admin ) {
              navigate ('/users', {
                  state: { from: '/' }
              })
          } else {
              navigate ('/credits', {
                  state: { from: '/' }
              })
          }
      } else {
          return ( 
              navigate ('/signin', {
                  state: { from: '/' }
              })
          )
      }
    }, [] )
    return (
      <div className={classes.content}>
        <CircularProgress color="secondary" />
      </div>
        
    );
}
