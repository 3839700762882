import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, SwipeableDrawer } from '@material-ui/core';
import { Grid } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `linear-gradient(#cfd9df,#e2ebf0)`,
    color: 'grey',
  },
  bigAvatar: {
    margin: 30,
    width: 100,
    height: 100,
  },
}));

function SideMenu( props ) {
    const classes = useStyles();
    function logIt () {
      console.log('open')
    }
    function onClick ( key ) {
        switch ( key ) {
            case 'Profile':
              window.location.href = '/profile';
              break;
            case 'Sign Out':
              sessionStorage.clear ();
              window.location.href = '';
              break;
        }
    }
    return (
      <SwipeableDrawer
        open={props.openDrawer}
        anchor='right'
        onOpen={logIt}
        onClose={props.toggleDrawer}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
            <Grid container justify='center' alignItems='center'>
              <Avatar
                  src='https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg'
                  className={classes.bigAvatar}/>
            </Grid>
            <List>
              {['Profile', 'Sign Out'].map((text, index) => (
                <ListItem button key={text} onClick={() => onClick(text)}>
                    <ListItemIcon>
                        {index % 2 === 0 ? <AccountCircle /> : <ExitToApp />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
      </SwipeableDrawer>
    );
}

export default SideMenu;