import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Button, CircularProgress, Container, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import auth from "../auth/auth-helper";
import { readCredit, readUser, updateCredits } from "./api-credits";
import { UserContext } from "../user/user-context";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    margin: "auto",
    position: "relative",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  titleCnt: {
    display: "flex",
    justifyContent: 'center',

  },
  media: {
    minHeight: 400,
  },
  sumbit: {
    margin: "10px",
    width: "auto",
    alignItems: 'middle',
    justifyContent: 'center',
  },
  btns: {
    padding: "16px",
    display: "flex"
  },
  credit: {
    padding: 10,
    textAlign: "right",
    backgroundColor: "#ededed",
    borderBottom: "1px solid #d0d0d0",
    "& a": {
      color: "#3f4771",
    },
  },
  content: {
    marginTop: '64px',
    display: 'flex',
    flex: 1,
    width: "600px",
    height: "320px",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eee'
  }
}));

export default function AddCredits(props) {
  const classes = useStyles();
  let { userId } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { selectedUser, setOpen, open } = props;
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    userId,
    credit: "",
    creditName: "",
    remark: "",
    error: "",
    added: false
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };


  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };
  function resetForm() {
    setValues({
      userId,
      credit: "",
      creditName: "",
      remark: "",
      error: "",
      added: false
    })
  }
  function validateNumber(str) {
    if (!isNaN(str) && !isNaN(parseFloat(str))) {
      return parseFloat(str);
    }
    return false;
  }
  const clickSubmit = async () => {
    setLoading(true);
    try {
      let { credit, remark, userId, creditName } = values;
      credit = validateNumber(credit)
      setValues({ ...values, credit });
      if (credit === false) {
        throw ('Enter a valid credit amount and try again')
      }
      const form_data = { userId, credit, remark, creditName };
      const data = await updateCredits(form_data)
      setValues({ ...values, added: true })
    } catch (e) {
      let error = e;
      if (typeof error !== 'string') {
        error = e.response.data.error;
      }
      setValues({ ...values, error });
    } finally {
      setLoading(false);
    }
  };
  const handleOk = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetForm()
    setOpen(false);
    window.location.reload();
  };
  if (loading) {
    return <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Add Credits
        <DialogContentText>User: {selectedUser.name}, <i>{selectedUser.email}</i></DialogContentText>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <CircularProgress color="secondary" />
        </div>
      </DialogContent>
    </Dialog>
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Add Credits
        <DialogContentText>User: {selectedUser.name}, <i>{selectedUser.email}</i></DialogContentText>
      </DialogTitle>
      {values.added ?
        <DialogContent>
          <Typography variant="h6" className={classes.title}>
            Credits added successfully!
          </Typography></DialogContent> :
        <DialogContent>{values.error ?
          <Container>
            <Typography variant="h6" className={classes.title}>
              {values.error}
            </Typography>
          </Container> :
          <Container>
            <TextField
              id="creditName"
              label="Credit Name"
              className={classes.textField}
              onChange={handleChange("creditName")}
              margin="normal"
            />
            <TextField
              id="credit"
              label="Credit"
              className={classes.textField}
              onChange={handleChange("credit")}
              margin="normal"
            />
            <TextField
              id="remark"
              type="remark"
              label="Remark"
              className={classes.textField}
              value={values.remark}
              onChange={handleChange("remark")}
              margin="normal"
              multiline
            />
          </Container>}
        </DialogContent>}
      {values.added ? <DialogActions>
        <Button variant="primary" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions> : <DialogActions>

        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={clickSubmit}>
          Submit
        </Button>
      </DialogActions>}
    </Dialog>
  );
}
