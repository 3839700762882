import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import auth from "../auth/auth-helper";
import { getRoleByID, read, update } from "./api-role";
import { Navigate } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";

const roleTypes = [
  {
    value: "admin",
    label: "$",
  },
  {
    value: "customer",
    label: "€",
  },
];

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(2),
    color: theme.palette.protectedTitle,
  },
  error: {
    verticalAlign: "middle",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function EditRole(props) {
  const classes = useStyles();
  const { data } = props.location;
  const [values, setValues] = useState({
    name: data.name,
    role: data.role,
    email: data.email,
    open: false,
    error: "",
    redirectToProfile: false,
  });
  const jwt = auth.isAuthenticated();

  const UpdateRole = async (role_data, credentials) => {
    try {
      let response = await fetch("/api/update/role", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + credentials.t,
        },
        body: JSON.stringify(role_data),
      });
      return await response.json();
    } catch (err) {
        console.error(err);
    }
  };

  const clickSubmit = () => {
    const role_data = {
      name: values.name || undefined,
      email: values.email || undefined,
      role: values.role || undefined,
    };

    UpdateRole(role_data, { t: jwt.token }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          error: "",
          open: true,
          redirectToProfile: true,
        });
      }
    });
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  if (values.redirectToProfile) {
    return <Navigate to={"/role/"} />;
  }
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" className={classes.title}>
            Edit User Role
          </Typography>
          <TextField
            id="name"
            label="Name"
            className={classes.textField}
            disabled={true}
            value={values.name}
            onChange={handleChange("name")}
            margin="normal"
          />
          <TextField
            id="email"
            type="email"
            label="Email"
            className={classes.textField}
            disabled={true}
            value={values.email}
            onChange={handleChange("email")}
            margin="normal"
          />
          <br />
          <TextField
            id="role"
            select
            label="Role"
            className={classes.textField}
            onChange={handleChange("role")}
            defaultValue="customer"
            helperText="Please select the role type"
          >
            {roleTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          <br />{" "}
          {values.error && (
            <Typography component="p" color="error">
              <Icon color="error" className={classes.error}>
                error
              </Icon>
              {values.error}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={clickSubmit}
            className={classes.submit}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
      <Dialog open={values.open} disableBackdropClick={true}>
        <DialogTitle>Role Update</DialogTitle>
        <DialogContent>
          <DialogContentText>Role Updated successfully.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/role/">
            <Button color="primary" autoFocus="autoFocus" variant="contained">
              Sign In
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
