import React, { useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import auth from "./auth-helper";
import { useLocation, useNavigate } from "react-router-dom";
import { signin } from "./api-auth.js";
import Logo from "./../assets/images/better_v.png";
import { UserContext } from "../user/user-context";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  loadingCard: {
    maxWidth: 600,
    height: 560,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: "black",
    fontfamily: "Montserrat",
    letterSpacing: "5px",
    fontSize: "26px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
    backgroundColor: "black",
    // border: "1px solid white",
    color: "white",
    borderRadius: "130px",
    width: "120px",
    padding: "10px",
  },
  logoCnt: {
    height: "128px",
    padding: 0,
  },
  logo: {
    height: "160px",
    width: "340px",
    margin: "auto",
  },
}));

export default function Signin(props) {
  const classes = useStyles();
  const state = useLocation();
  const { user, setUser } = useContext(UserContext);
  const to = state || {
    from: "/",
  };
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const clickSubmit = (e) => {
    e.preventDefault();
    const user = {
      email: values.email || undefined,
      password: values.password || undefined,
    };

    signin(user)
      .then((resp) => {
        setValues({ ...values, loading: true });
        const { data } = resp;
        auth.authenticate(data, () => {
          setUser(data.user);
          let { pathname } = to;
          if (pathname) {
            if (pathname.includes("signin")) {
              pathname = "/";
            }
            window.location.href = pathname;
          } else {
            navigate(to.from);
          }
          setValues({ ...values, loading: false });
        });
      })
      .catch((error) => {
        const { data } = error.response;
        setValues({ ...values, error: data.error, loading: false });
      });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
      {values.loading ? (
        <Card className={classes.loadingCard}>
          <CardContent>
            <CircularProgress></CircularProgress>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <img className={classes.logo} src={Logo} srcSet={Logo} />
            <Typography variant="h6" className={classes.title}>
              Welcome to Better Vision
            </Typography>
            <TextField
              id="email"
              type="email"
              label="Email"
              className={classes.textField}
              value={values.email}
              onChange={handleChange("email")}
              margin="normal"
            />
            <br />
            <TextField
              id="password"
              type="password"
              label="Password"
              className={classes.textField}
              value={values.password}
              onChange={handleChange("password")}
              margin="normal"
            />
            <br />{" "}
            {values.error && (
              <Typography component="p" color="error">
                <Icon color="error" className={classes.error}></Icon>
                {values.error}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={clickSubmit}
              className={classes.submit}
            >
              Sign In
            </Button>
          </CardActions>
          <div>
            <Link to="/forgotpassword">
              <Button color="inherit">Forgot Password</Button>
            </Link>
          </div>
          <div>
            <Link to="/signup">
              <Button color="inherit">
                Do not have an account? Click to Sign Up
              </Button>
            </Link>
          </div>
        </Card>
      )}
    </div>
  );
}
