import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link } from "react-router-dom";
import { forgotpasswordresetlink } from "./api-auth.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

const passwordReset = async (user) => {
  try {
    let response = await fetch("/auth/password/reset/emaillink", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // credentials: "include",
      body: JSON.stringify(user),
    });
    return await response.json();
  } catch (err) {
    console.error(err);
  }
};

const PasswordResetLink = (props) => {
  const classes = useStyles();
  const { userId, token } = useParams();

  const [values, setValues] = useState({
    userId: "",
    token: "",
    password: "",
    error: "",
    open: false,
  });
  const clickSubmit = () => {
    const user = {
      userId: userId || undefined,
      token: token || undefined,
      password: values.password || undefined,
    };
    forgotpasswordresetlink(user).then((resp) => {
        setValues({
          ...values,
          userId: userId,
          token: token,
          open: true,
          error: "",
        });
    }
    
    ).catch((error) => {
      const { data } = error.response;
      setValues({ ...values, open: true, error: data.error });
    }
    );
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography>Forgot Password </Typography>
          userID: {userId} <br></br>
          token: {token}
          <TextField
            id="password"
            type="password"
            label="Create your new password"
            className={classes.textField}
            value={values.password}
            onChange={handleChange("password")}
            margin="normal"
          />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={clickSubmit}
              className={classes.submit}
            >
              Reset your password
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      <Dialog open={values.open} disableBackdropClick={true}>
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {values.error
              ? "User Email not found."
              : "Your password has been successfully updated."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/signin">
            <Button color="primary" autoFocus="autoFocus" variant="contained">
              Sign In
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordResetLink;
