import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { UserContext } from "../user/user-context";
import { textAlign } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    margin: "auto",
    backgroundColor: "cyan",
    height: "400",
  },
  title: {
    padding: "2px",
    textAlign: "center",
    marginTop: "10 px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  titleCnt: {
    padding: "2px 2px",
  },
  media: {
    minHeight: 400,
  },
  sumbit: {
    margin: "10px",
    width: "auto",
    alignItems: "middle",
    justifyContent: "center",
  },
  btns: {
    padding: "16px",
    display: "flex",
  },
  credit: {
    padding: 10,
    textAlign: "right",
    backgroundColor: "#ededed",
    borderBottom: "1px solid #d0d0d0",
    "& a": {
      color: "#3f4771",
    },
  },
}));

export default function CreditsControl(props) {
  const classes = useStyles();
  const { selectedUser, addCredits, redeemCredits } = props;
  const [open, setOpen] = useState(false); // close the dialog box
  const { user, setUser } = useContext(UserContext);

  const handleClick = (event) => {};
  const handleClose = () => {};
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <AppBar
      position="static"
      sx={{
        color: "primary.dark",
        backgroundColor: "primary.contrastText",
      }}
    >
      <Toolbar className={classes.titleCnt} disableGutters>
        <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
          <Typography variant="h6" className={classes.title}>
            Points summary for <b>&nbsp;{selectedUser.name}</b>{" "}
            <i>({selectedUser.email})</i>
          </Typography>
        </Box>

        {user.admin && (
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box sx={{ float: "right" }}>
              {/* <Typography variant="h6" className={classes.title}>
                <b>{selectedUser.name}</b>{" "}
              </Typography> */}
              <Box>
                <IconButton
                  size="large"
                  id="credits-positioned-menu"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={redeemCredits}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
                <IconButton
                  size="large"
                  id="credits-positioned-menu"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={addCredits}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>
            </Box>

            <Button
              color="primary"
              variant="outlined"
              onClick={redeemCredits}
              className={classes.submit}
              sx={{ margin: "8px" }}
              startIcon={<RemoveCircleOutlineIcon />}
            >
              Points
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={addCredits}
              className={classes.submit}
              sx={{ margin: "8px" }}
              startIcon={<AddCircleOutlineIcon />}
            >
              Points
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
