import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { UserContext } from "../user/user-context";
import auth from "./auth-helper";

const PrivateRoute = ({ children }) => {
  const { user, setUser } = useContext(UserContext);
  if (!user) {
    return <Navigate to="/signin" replace />;
  }
  return children;
};

export default PrivateRoute;
