import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { forgotpassword } from "./api-auth.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));


const ForgotPassword = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    email: "",
    error: "",
    open: false,
  });

  const [progress, setFlag] = useState(false);

  const clickSubmit = () => {
    const user = {
      email: values.email || undefined,
    };

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.email) == false) {
      alert("Please provide valid email id");
      return;
    }
    let pobj = forgotpassword(user);

    pobj.then((resp) => {

      setFlag(true);

      setTimeout(() => {
        //setValues({ ...values, open: true, error: "" });

        setFlag(false);
        setValues(prevState => {
          return {
            ...prevState,
            open: true,
            error: ""
          }

        });
      }, 2000);

      return resp;

    }).catch((error) => {
      // const { data } = error.response;
      //setValues({ ...values, open: true, error: error });
      console.log()
      setValues(prevState=>{
        return {
          ...prevState,
          open: true, 
          error: error
        }
      })
    });
  };


  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
      <Card className={classes.card}>
        {progress ?
          <span><LinearProgress /></span> : false}

        <CardContent>
          <Typography>Forgot Password  {/* <pre>{JSON.stringify(values,null,3)}</pre> */}</Typography>
          <TextField
            id="email"
            name="email"
            label="email"
            value={values.email}
            onChange={handleChange("email")}
            className={classes.textField}
          ></TextField>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={clickSubmit}
              className={classes.submit}
            >
              Send Reset Code to email
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      <Dialog open={values.open} disableBackdropClick={true}>
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {values.error
              ? "User Email not found."
              : "Please check your email for password reset code."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/signin">
            <Button color="primary" autoFocus="autoFocus" variant="contained">
              Sign In
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ForgotPassword;
