import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Person from "@material-ui/icons/Person";
import { list } from "./api-user.js";
import { Card, CircularProgress, TextField } from "@material-ui/core";
import EnhancedTable from "../core/EnhancedTable.jsx";

const useStyles = makeStyles((theme) => ({
  root: theme.mixins.gutters({
    padding: theme.spacing(1),
    margin: theme.spacing(5),
  }),
  title: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      2
    )}px`,
    flex: 1,
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  titleCnt: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  content: {
    marginTop: "64px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#eee",
  },
}));

export default function Users({ details }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const filteredUsers = users.filter((user) => {
    return (
      user.firstname
        ?.toString()
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
      user.email
        ?.toString()
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
      user.lastname
        ?.toString()
        .toLowerCase()
        .includes(searchField.toLowerCase())
    );
  });
  const headCells = [
    {
      id: "picture",
      numeric: false,
      iconButton: true,
      icon: (
        <ListItemAvatar>
          <Avatar>
            <Person />
          </Avatar>
        </ListItemAvatar>
      ),
      disablePadding: false,
      label: "Picture",
    },
    {
      id: "firstname",
      numeric: false,
      disablePadding: false,
      label: "First Name",
    },
    {
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "profile",
      numeric: false,
      linkButton: true,
      link: "/users/",
      disablePadding: false,
      label: "Check Profile",
    },
    {
      id: "credits",
      link: "/credits/user/",
      numeric: false,
      linkButton: true,
      disablePadding: false,
      label: "Check Loyalty Points",
    },
  ];
  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };

  useEffect(() => {
    async function loadData() {
      try {
        const data = await list();
        const users = data.data.map((d) => {
          d.count = 1;
          return d;
        });
        setUsers(users);
        console.log(users);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);
  function searchList() {
    if (searchShow) {
      return <List filteredUsers={filteredUsers} />;
    }
  }
  if (loading) {
    return (
      <div className={classes.content}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <Paper>
      <Card className={classes.titleCnt}>
        <Typography variant="h6" className={classes.title}>
          {" "}
          Customers
        </Typography>
        <div className="pa2">
          <TextField
            className={classes.textField}
            type="search"
            placeholder="Search Customers"
            onChange={handleChange}
          />
        </div>
      </Card>
      <EnhancedTable
        headCells={headCells}
        rows={filteredUsers}
        rowId="_id"
        title="Customer List"
      />
    </Paper>
  );
}
