import React, { useState, useEffect, useContext } from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import auth from '../auth/auth-helper'
import { read, update } from './api-user.js'
import { Navigate, useParams } from 'react-router-dom'
import { UserContext } from './user-context'
import { readUser } from '../credits/api-credits'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2)
  },
  title: {
    margin: theme.spacing(2),
    color: theme.palette.protectedTitle
  },
  error: {
    verticalAlign: 'middle'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300
  },
  submit: {
    margin: 'auto',
    marginBottom: theme.spacing(2)
  }
}))

export default function EditProfile({ match }) {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  let { userId } = useParams();
  const [values, setValues] = useState({
    name: '',
    password: '',
    email: '',
    open: false,
    error: '',
    redirectToProfile: false
  })

  useEffect(() => {
    if (userId) {
      readUser(userId).then((data) => {
        if (data && data.error) {
          setValues({ ...values, error: data.error })
        } else {
          setValues({ ...values, name: data.data.name, email: data.data.email })
        }
      })
    } else {
      setValues({ ...values, name: user.name, email: user.email })
    }
  }, [userId])

  const clickSubmit = () => {
    const user = {
      name: values.name || undefined,
      email: values.email || undefined,
      password: values.password || undefined,

    }
    update({
      userId: match.params.userId
    }, user).then((data) => {
      if (data && data.error) {
        setValues({ ...values, error: data.error })
      } else {
        setValues({ ...values, userId: data._id, redirectToProfile: true })
      }
    })
  }
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  if (values.redirectToProfile) {
    return (<Navigate to={'/user/' + values.userId} />)
  }
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          Edit Profile
        </Typography>

        <TextField id="name" label="Name" className={classes.textField} value={values.name} onChange={handleChange('name')} margin="normal" /><br />

        <TextField id="email" type="email" label="Email" className={classes.textField} value={values.email} onChange={handleChange('email')} margin="normal" /><br />

        <TextField id="mobile" label="Mobile Number" className={classes.textField} value={values.mobile} onChange={handleChange('Mobile')} margin="normal" /><br />

        <TextField id="password" type="password" label="Password" className={classes.textField} value={values.password} onChange={handleChange('password')} margin="normal" />

        <TextField id="confirm" label="Conform Password" className={classes.textField} value={values.confirm} onChange={handleChange('Conform')} margin="normal" /><br />



        <br /> {
          values.error && (<Typography component="p" color="error">
            <Icon color="error" className={classes.error}>error</Icon>
            {values.error}
          </Typography>)
        }
      </CardContent>
      <CardActions>
        <Button color="primary" variant="contained" onClick={clickSubmit} className={classes.submit}>Submit</Button>
      </CardActions>
    </Card>
  )
}

