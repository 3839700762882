import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import Header from "./Header";
import { RouterProvider, useNavigate } from "react-router-dom";
import router from "../Router";
import { UserContext } from "../user/user-context";
import { CircularProgress } from "@material-ui/core";
import { me } from "../user/api-user";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flex: 1
    },
    rootLoader: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flex: 1,
        alignItems: 'center',
    justifyContent: 'center',
    },
    content: {
        paddingTop: '64px',
        display: 'flex',
        flex: 1,
        flexDirection:'column',
        background: '#eee'
    }
}));

export default function Home() {
    const classes = useStyles ();
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const { user, setUser } = useContext ( UserContext );
    function toggleDrawer () {
        setOpenDrawer(!openDrawer)
    }
    useEffect(() => {
        setLoading ( true );
        me ().then ( resp => {
            setLoading ( false );
            const { data } = resp;
            if ( data ) {
                setUser ( data );
            }
        }).catch ( e => {
            console.error('here is the errorrrrr')
            setUser(null)
            setLoading ( false );
        });
    }, [] )
    if ( loading ) {
        return <div className={classes.rootLoader}><CircularProgress/></div>
    }
    return (
        <div className={classes.root}>
            <Header toggleDrawer={toggleDrawer} />
            <div className={classes.content}>
                <RouterProvider router={router}/>
            </div>
            <SideMenu openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
            <Footer />
        </div>
    );
}
