import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { create } from "./api-user.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Logo from "./../assets/images/better_v.png";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: "black",
    fontfamily: "Montserrat",
    letterSpacing: "5px",
    fontSize: "26px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
  logoCnt: {
    height: "128px",
    padding: 0,
  },
  logo: {
    height: "128px",
  },
}));

export default function Signup() {
  const classes = useStyles();
  const [values, setValues] = useState({
    firstname: "",
    surname: "",
    password: "",
    mobile: "",
    email: "",
    open: false,
    error: "",
    confirm: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const clickSubmit = async (e) => {
    e.preventDefault();

    console.log(JSON.stringify(values, null, 3));
    const user = {
      firstname: values.firstname || undefined,
      surname: values.surname || undefined,
      mobile: values.mobile || undefined,
      email: values.email || undefined,
      password: values.password || undefined,
    };

    create(user)
      .then((response) => {
        setValues({ ...values, error: "", open: true });
        console.log(JSON.stringify(response, 3, null));
      })
      .catch((error) => {
        const { data } = error.response;
        setValues({ ...values, error: data.error });
      });
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <img className={classes.logo} src={Logo} srcSet={Logo} />
          <Typography variant="h6" className={classes.title}>
            <b>Blacktown Better Vision Loyalty</b>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            Sign Up
          </Typography>
          <TextField
            id="firstname"
            label="Name"
            className={classes.textField}
            value={values.name}
            onChange={handleChange("firstname")}
            margin="normal"
          />
          <br />
          <TextField
            id="surname"
            label="Surname"
            className={classes.textField}
            value={values.surname}
            onChange={handleChange("surname")}
            margin="normal"
          />
          <br />
          {/* "We will use your mobile to keep you updated on your credits expiry" */}
          <Tooltip
            title={
              <h3 style={{ color: "white" }}>
                We will use your mobile to keep you updated on your credits
                expiry
              </h3>
            }
            placement="top-start"
          >
            <TextField
              id="mobile"
              label="Mobile Number"
              className={classes.textField}
              value={values.mobile}
              onChange={handleChange("mobile")}
              margin="normal"
            />
          </Tooltip>
          <br />
          <TextField
            id="email"
            type="email"
            label="Email"
            className={classes.textField}
            value={values.email}
            onChange={handleChange("email")}
            margin="normal"
          />
          <br />
          <TextField
            id="password"
            type="password"
            label="Password"
            className={classes.textField}
            value={values.password}
            onChange={handleChange("password")}
            margin="normal"
          />
          <br />{" "}
          <TextField
            error={values.password != values.confirm}
            id="confirm"
            type="password"
            label="Confirm Password"
            className={classes.textField}
            value={values.confirm}
            onChange={handleChange("confirm")}
            margin="normal"
          />
          <br />{" "}
          {values.error && (
            <Typography component="p" color="error">
              <Icon color="error" className={classes.error}></Icon>
              {values.error}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={clickSubmit} // onClick
            className={classes.submit}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
      <Dialog open={values.open} disableBackdropClick={true}>
        <DialogTitle>New Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New account successfully created.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/signin">
            <Button color="primary" autoFocus="autoFocus" variant="contained">
              Sign In
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
