import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import UserIcon from "@material-ui/icons/Face";
import { MenuItem } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import { padding } from "@mui/system";
import Logo from "../assets/images/white_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#1f1f1f",
    color: "white",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    display: "flex",
  },
  logoCnt: {
    height: "64px",
    marginLeft: "-24px",
    padding: 0,
  },
  logo: {
    height: "64px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: "80px",
    width: "120px",
  },
}));

function Header(props) {
  const classes = useStyles();
  function headClick() {
    window.location.href = "/";
  }
  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar>
        <img
          className={classes.logo}
          src={Logo}
          srcSet={Logo}
          onClick={headClick}
        />

        <MenuItem className={classes.grow} onClick={headClick}>
          {/* <Typography variant='h6' className={classes.title}>
            Better Vision Credits
          </Typography> */}
        </MenuItem>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          onClick={props.toggleDrawer}
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
