import React from "react";
import Home from "./core/Home";
import Credits from "./credits/Credits";
import Users from "./user/Users";
import Signup from "./user/Signup";
import Signin from "./auth/Signin";
import EditProfile from "./user/EditProfile";
import Profile from "./user/Profile";
import PrivateRoute from "./auth/PrivateRoute";
import AdminRoute from "./auth/AdminRoute";
import Menu from "./core/Menu";
import EditRole from "./role/EditRole";
import ForgotPassword from "./auth/ForgotPassword";
import PasswordResetLink from "./auth/PasswordResetLink";
import Role from "./role/Role";
import {
  createBrowserRouter,
} from "react-router-dom";
import LandingPage from "./core/LandingPage";
import Customer from "./core/Customer";
import BasicCard from "./credits/BasicCard";

const router = createBrowserRouter([{
  path: "/",
  element: <LandingPage />,
}, {
  path: "/signup",
  element: <Signup />,
}, {
  path: "/signin",
  element: <Signin />,
}, {
  path: "/auth/password/reset/:userId/:token",
  element: <PasswordResetLink />,
}, {
  path: "/forgotpassword",
  element: <ForgotPassword />,
},
 {
  path: "/credits",
  element: <PrivateRoute><Credits /></PrivateRoute>,
}, 
{
  path: "/role",
  element: <AdminRoute><Role /></AdminRoute>,
}, {
  path: "/edit/role",
  element: <AdminRoute><EditRole /></AdminRoute>
}, {
  path: "/profile",
  element: <PrivateRoute><Profile /></PrivateRoute>
}, {
  path: "/user/edit",
  element: <PrivateRoute><EditProfile /></PrivateRoute>
}, {
  path: "/user/edit/:userId",
  element: <AdminRoute><EditProfile /></AdminRoute>
}, {
  path: "/users",
  element: <AdminRoute><Users /></AdminRoute>
}, , {
  path: "/users/:userId",
  element: <AdminRoute><EditProfile /></AdminRoute>
}, {
  path: "/credits/user/:userId",
  element: <AdminRoute><Credits /></AdminRoute>
}, {
  path: "/credits/edit/:userId",
  element: <PrivateRoute><Credits /></PrivateRoute>
}, 
// {
//   path: "/credits",
//   element:<BasicCard />
// },
]);

export default router;
