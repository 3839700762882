import { signout } from "./api-auth.js";

const auth = {
  isAuthenticated() {
    if (typeof window == "undefined") return false;

    if (sessionStorage.getItem("token")) {
      return sessionStorage.getItem("token");
    } else return false;
  },
  isAdmin() {
    if (typeof window == "undefined") return false;
    const roleData = JSON.parse(sessionStorage.getItem("token"));
    if (!roleData) return false;
    const isAdmin = roleData.user.role[0] === "admin";
    if (isAdmin) {
      return JSON.parse(sessionStorage.getItem("token"));
    } else return false;
  },
  authenticate(payload, cb) {
    const {token} = payload;
    if (typeof window !== "undefined" && token){
      sessionStorage.setItem("token", token);
    } 
    cb();
  },
  clearJWT(cb) {
    if (typeof window !== "undefined") sessionStorage.removeItem("token");
    cb();
    //optional
    signout().then((data) => {
      document.cookie = "t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    });
  },
};

export default auth;
