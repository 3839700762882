import React, { useContext, useEffect, useMemo, useState } from "react";
import router from "./Router";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Home from "./core/Home";
import { UserContext } from "./user/user-context";


const App = () => {
  const [user, setUser] = useState({})
  const value = useMemo(
    () => ({ user, setUser }), [{}]
  );
  useEffect (() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, [])
  return (
      <UserContext.Provider value={value}>
          <ThemeProvider theme={theme}>
            <Home/>
          </ThemeProvider>
      </UserContext.Provider>
  );
};

export default App;
