import api from "../api"

const signin = async (user) => {
    return api.post (`/auth/signin/`, user )
}

const signout = async () => {
    return api.get (`/auth/signout/`)
}

const forgotpassword = async (email) => {
  return await api.post (`/auth/password/reset`, email )
}

const forgotpasswordresetlink = async (user) => {
  return api.post (`/auth/password/reset/emaillink`, user )
}

export {
  signin,
  signout,
  forgotpassword,
  forgotpasswordresetlink
}