import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Button, CircularProgress, Container, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { validateCredits, redeemCredits } from "./api-credits";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    margin: "auto",
    position: "relative",
  },
  
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  titleCnt: {
    display: "flex",
    justifyContent: 'center',
    
  },
  media: {
    minHeight: 400,
  },
  sumbit: {
    margin: "10px",
    width: "auto",
    alignItems: 'middle',
    justifyContent: 'center',
  },
  btns: {
    padding: "16px",
    display: "flex"
  },
  credit: {
    padding: 10,
    textAlign: "right",
    backgroundColor: "#ededed",
    borderBottom: "1px solid #d0d0d0",
    "& a": {
      color: "#3f4771",
    },
  },
  content: {
    marginTop: '64px',
    display: 'flex',
    flex: 1,
    width: "400px",
    height: "320px",
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eee'
  }
}));

export default function RedeemCredits( props ) {
    const classes = useStyles();
    let { userId } = useParams();
    const { selectedUser, setOpen, open } = props;
    const [ loading, setLoading ] = useState(false);
    const [values, setValues] = useState({
        userId,
        credit: "",
        remark: "",
        validated: false,
        error: null,
        redeemed: false
    });
    
    function resetForm () {
        setValues ({
            userId: userId,
            credit: "",
            remark: "",
            validated: false,
            error: null,
            redeemed: false
        })
    }
    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleTryAgain =  (e, reason) => {
        resetForm ();
    }
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        resetForm ()
        setOpen(false);
    };
    const handleOk = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        resetForm ()
        setOpen(false);
        window.location.reload ();
    };
    function validateNumber ( str ) {
        if ( !isNaN ( str ) && !isNaN(parseFloat(str)) ) {
            return parseFloat(str);
        }
        return false;
    }
    const clickValidate = async () => {
      setLoading ( true )
        try {
            let { credit, remark, userId } = values;
            credit = validateNumber ( credit )
            setValues ( {...values, credit });
            if ( credit === false ) {
                throw ('Enter a valid credit amount and try again')
            } 
            const form_data = { userId, credit, remark };
            const data = await validateCredits(form_data)
            setValues ( {...values, validated: true })
        } catch (e) {
            let error = e;
            if ( typeof error !== 'string' ) {
                error = e.response.data.error;
            }
            setValues({ ...values, error });
        } finally {
          setLoading ( false )
        }
    };

    const clickRedeem  = async () => {
        try {
            let {credit, remark, userId} = values;
            credit = validateNumber ( credit )
            setValues ( {...values, credit });
            if ( credit === false ) {
                throw ('Enter a valid credit amount and try again')
            } 
            const form_data = { userId, credit, remark };
            const data = await redeemCredits(form_data)
            setValues ( {...values, redeemed: true })
        } catch (e) {
            console.log ({e});
            const error = e.response.data.error;
            setValues({ ...values, error });
        }
    };
    
    if ( loading ) {
      return <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Add Credits
              <DialogContentText>User: {selectedUser.name}, <i>{selectedUser.email}</i></DialogContentText>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.content}>
                <CircularProgress color="secondary" />
            </div>
          </DialogContent>
      </Dialog>
    }

    return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
              Redeem Credits
            <DialogContentText>User: {selectedUser.name}, <i>{selectedUser.email}</i></DialogContentText>
          </DialogTitle>
          { values.redeemed ? 
            <DialogContent>
                <Typography variant="h6" className={classes.title}>
                    <b>${values.credit.toLocaleString()}</b> credits redeeemed successfully!
                </Typography>
            </DialogContent> : 
            <DialogContent>
            <DialogContentText></DialogContentText>
            { values.error ? <Container>
              <Typography variant="h6" className={classes.title}>
                {values.error}
              </Typography>
            </Container> : 
            values.validated ? <Container>
            <Typography variant="h6" className={classes.title}>
                  Are you sure you want to redeeem <b>${values.credit.toLocaleString()}</b> credits?
                </Typography>
            </Container>: <Container>
            <TextField
                id="credit"
                label="Redeem Credit Amount"
                className={classes.textField}
                onChange={handleChange("credit")}
                margin="normal"
              />
              <br />
              <TextField
                id="remark"
                type="remark"
                label="Remark"
                className={classes.textField}
                value={values.remark}
                onChange={handleChange("remark")}
                margin="normal"
                multiline
              />

            <br />
          </Container> 
          }
          </DialogContent> }
          { values.redeemed ? <DialogActions>
              <Button variant="outlined" onClick={handleOk}>
                Okay!
              </Button></DialogActions>: <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              { values.error ?
              <Button color="primary" variant="contained" onClick={handleTryAgain}>
              Try Again
            </Button>: 
            values.validated ? 
            <Button color="primary" variant="contained" onClick={clickRedeem}>
                Redeem
              </Button> : 
              <Button color="primary" variant="contained" onClick={clickValidate}>
                Validate
              </Button>}
            </DialogActions> } 
        </Dialog>
    );
}
